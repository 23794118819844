import React from 'react';
import styled from 'styled-components';
import {message, Table, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {timestampFormat} from '../Utils/DateUtil';
import {errorHandler} from '../errors';
const appConfig = require('../data.json');

function CategoryList(props) {
  const [loading, setLoading] = useOutlet('loading');
  const [customerServiceInfo, setCustomerServiceInfo] = React.useState([
    {
      display: '成立訂單注意事項',
    },
  ]);
  const [actions] = useOutlet('actions');

  return (
    <Wrapper>
      <Table
        loading={loading}
        dataSource={customerServiceInfo}
        columns={[
          {key: 'display', title: '名稱', dataIndex: 'display'},
          {
            key: 'action',
            title: '',
            dataIndex: '',
            render: (_, record) => (
              <div>
                <Button
                  onClick={() =>
                    navigate(`/customer_service_info/edit?key=agreement`)
                  }>
                  編輯
                </Button>
              </div>
            ),
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f5f5f5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .top-container {
    margin-bottom: 20px;
  }
`;

export default CategoryList;
