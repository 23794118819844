import moment from 'moment';
/*moment: https://momentjs.com/docs/#/displaying/ */

let _moment = moment('2017-01-01');

let dateParser = (string) => {
  if (moment(string).isValid()) {
    // change to local timezone display
    return moment(string).local().format('YYYY-MM-DD HH:mm');
  } else {
    return '';
  }
};

let parseSecToString = (sec) => {
  return _moment.startOf('day').add(sec, 'seconds').format('H:mm:ss');
};

function d2s(date) {
  if (date) {
    // change to local timezone display
    return moment(date).local().format('YYYY-MM-DD');
  }
  return null;
}

function s2d(str) {
  if (str) {
    try {
      return moment(str).toDate();
    } catch (ex) {
      // bypass
    }
  }

  return null;
}

function dateFormat(str) {
  return moment(str).format('MMM DD, YYYY');
}

function getDiffDate(
  days,
  options = {operator: '+', format: 'YYYY-MM-DD', interval: 'days'},
) {
  let today = moment().local();
  if (options.operator === '+') {
    today.add(days, options.interval);
  } else {
    today.subtract(days, options.interval);
  }

  return today.format(options.format);
}

function timestampFormat(date) {
  try {
    if (isNaN(date)) {
      throw new Error();
    }
    let value = date;

    // timestamp digit < 13, not millisecond
    if (date.toString().length < 13) {
      value = date * 1000;
    }

    // timezone offset;
    const offset = new Date().getTimezoneOffset() * 60000;
    const str = new Date(value - offset).toISOString();

    // formating YYYY-MM-DD HH:mm
    return str.slice(0, 16).replace('T', ' ');
  } catch (error) {
    return '-';
  }
}

export {
  dateParser,
  parseSecToString,
  d2s,
  s2d,
  dateFormat,
  getDiffDate,
  timestampFormat,
};
